import background from './img/hero-bg.jpeg';
import mapa from './img/mapa.png';
import overlayBg from './img/overlay-bg.jpg';
import './App.css';
import { Col, Container, Row } from 'react-bootstrap';

function App() {
  return (
    <div className="App">
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between">

          <h1 className="logo">
            {/* <a href="#">DevFolio</a> */}
          </h1>

          <nav id="navbar" className="navbar">
            <ul>
              <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
              <li><a className="nav-link scrollto" href="#about">Musica</a></li>
              <li><a className="nav-link scrollto" href="#contact">Contacto</a></li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>

        </div>
      </header>
      <div id="hero" className="hero route bg-image" style={{ color: "white", backgroundImage: `url('${background}')` }}>
        <div className="overlay-itro"></div>
        <div className="hero-content display-table">
          <div className="table-cell">
            <div className="container">
              <h1 className="hero-title mb-4">NUTE</h1>
              <p className="hero-subtitle"><span className="typed" data-typed-items="Y LOS DESARRAIGADOS">Y LOS DESARRAIGADOS</span></p>
              {/* <p className="pt-3"><a className="btn btn-primary btn js-scroll px-4" href="#about" role="button">Learn More</a></p> */}
            </div>
          </div>
        </div>
      </div>

      <main id="main">
        <section id="about" className="about-mf sect-pt4 route">
          <Container>
            <Row>
              <Col sm={12}>
                <div className="title-box text-center">
                  <h3 className="title-a">
                    Musica
                  </h3>
                  <p className="subtitle-a">
                    Escucha nuestras canciones en todas las plataformas de streaming.
                  </p>
                  <div className="line-mf"></div>
                </div>

                <div className="row" style={{ marginBottom: "3rem" }}>
                  {/* <Spotify link="https://open.spotify.com/artist/0Pd2gLjVvV80SHGYi7gHhy" /> */}
                  <iframe title='spotify' style={{ borderRadius: "12px" }} src="https://open.spotify.com/embed/artist/0Pd2gLjVvV80SHGYi7gHhy?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="contact" className="paralax-mf footer-paralax bg-image sect-mt4 route" style={{ backgroundImage: `url('${overlayBg}')` }}>
          <div className="overlay-mf"></div>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="contact-mf">
                  <div id="contact" className="box-shadow-full">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="title-box-2">
                          <h5 className="title-left">
                            Contacto
                          </h5>
                        </div>
                        <div>
                          <form action="forms/contact.php" method="post" className="php-email-form">
                            <div className="row">
                              <div className="col-md-12 mb-3">
                                <div className="form-group">
                                  <input type="text" name="name" className="form-control" id="name" placeholder="Tu Nombre" required />
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="form-group">
                                  <input type="email" className="form-control" name="email" id="email" placeholder="Tu Email" required />
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="form-group">
                                  <input type="text" className="form-control" name="subject" id="subject" placeholder="Asunto" required />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <textarea className="form-control" name="message" rows="5" placeholder="Mensaje" required></textarea>
                                </div>
                              </div>
                              <div className="col-md-12 text-center my-3">
                                <div className="loading">Cargando</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Mensaje enviado correctamente. Gracias!</div>
                              </div>
                              <div className="col-md-12 text-center">
                                <button type="submit" className="button button-a button-big button-rouded">Enviar Mensaje</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="title-box-2 pt-4 pt-md-0">
                          <h5 className="title-left">
                            Adonde estamos?
                          </h5>
                        </div>
                        <div className="more-info">
                          <p className="lead">
                            <img src={mapa} style={{ maxWidth: "100%" }} alt="" />
                          </p>
                          <ul className="list-ico">
                            <li><span className="bi bi-geo-alt"></span> BARCELONA, ESPAÑA</li>
                            <li><span className="bi bi-envelope"></span>rebecchialejandro@gmail.com</li>
                          </ul>
                        </div>
                        {/* <div className="socials">
                          <ul>
                            <li><a href="#"><span className="ico-circle"><i className="bi bi-facebook"></i></span></a></li>
                            <li><a href="#"><span className="ico-circle"><i className="bi bi-instagram"></i></span></a></li>
                            <li><a href="#"><span className="ico-circle"><i className="bi bi-twitter"></i></span></a></li>
                            <li><a href="#"><span className="ico-circle"><i className="bi bi-linkedin"></i></span></a></li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="copyright-box">
                  <p className="copyright">&copy; Copyright <strong>DevFolio</strong>. All Rights Reserved</p>
                  <div className="credits">
                    Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </div>
  );
}

export default App;
